import React from 'react';

const initialFormState = {
}

const NotFound = () => {
    return (
        <div>404 no privacy here!</div>
    );
};

export default NotFound;