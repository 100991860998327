import React, { Component} from 'react';
import styles from "./Tell.scss"
import StoriesContext from '../../../../state/StoriesContext';
import { Formik, Field, ErrorMessage } from 'formik';
import ReactDOM from 'react-dom';
import { Badge, Form, FormControl, Button, Col, Feedback, Container, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';
import * as Yup from 'yup';
import ImageUpload from "./imageUpload";
import Geocode from "react-geocode";
import { Link } from "react-router-dom";
import LocationPick from "./locationPick";

var slugify = require('slugify')


const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, "Must have character")
        .max(255, "must be shorter")
        .required("Must be valid"),
    story: Yup.string()
        .min(25, "Longer story required (min 25 characters)")
        .max(3000, "must be shorter")
        .required("Must be valid"),
    twitterHandle: Yup.string()
        .min(1, "Must have character")
        .max(124, "must be shorter"),
    imgUrl: Yup.string()
        .min(10, "not valid")
        .required("Must be valid"),
    locationName: Yup.string()
        .min(1, "Location access of your browser required")
        .max(255, "must be shorter")
        .required("Must be valid")
})


const Error = ({ touched, message }) => {
    if(!touched) {
        return <div className="form-message invalid"></div>
    }
    if(message) {
        return <div className="form-message invalid">{message}</div>
    }
    return <div className="form-message invalid">valid</div>
}

class ExploreList extends Component {
    static contextType = StoriesContext
    
    
    constructor() {
        super()

        this.state = {
            stories: [],
            imgPath: '',
            storyPath: null,
            location: {
                name: null,
                lat: null,
                lng: null
            }
        };

        this.setImagePath = this.setImagePath.bind(this);
        this.setLocationFromPicker = this.setLocationFromPicker.bind(this);
        this.myRefImgPath = React.createRef();
        this.myRefCityName = React.createRef();
       
    }

    componentDidMount() {
    }

    setImagePath(path) {
        this.setState({
            imgPath: path
        });

        var input = ReactDOM.findDOMNode(this.myRefImgPath.current);
        let event = new Event('input', {  bubbles: true  });
        let tracker = input._valueTracker;
        tracker.setValue("");
        input.dispatchEvent(event);
    }

    setLocationFromPicker(location) {
        var lat = location.lat + ((-.5+Math.random())*0.01)
        var lng = location.lng + ((-.5+Math.random())*0.01)

        this.setState({
            ...this.state,
            location: {
                name: location.name,
                lat: lat,
                lng: lng
            }
        })
        
        // DISPATCH INPUT
        var input = ReactDOM.findDOMNode(this.myRefCityName.current);
        let event = new Event('input', {  bubbles: true  });
        let tracker = input._valueTracker;
        tracker.setValue("");
        input.dispatchEvent(event);

        const { updateCenter } = this.context

        updateCenter(
            {
              lat: lat, lng: lng
            },
            {
              radius: 1000
            }
        )
    }
  
    
    render() {
        return(
            <Container >  
                <Formik 
                initialValues={{
                    firstName: "",
                    story: "", 
                    twitterHandle: "",
                    imgUrl: "",
                    locationName: ""
                }} 
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {

                    setSubmitting(true);

                    const { addStory } = this.context;

                    
                    setTimeout(() => {
                        // post values
                        var story = {
                            id: slugify(`${Date.now()}_${values.firstName}`),
                            timeStamp: Date.now(),
                            firstName: values.firstName,
                            story: values.story,
                            twitterHandle: values.twitterHandle,
                            imgUrl: values.imgUrl,
                            likes: 0,
                            location: {
                                name: values.locationName,
                                lat: this.state.location.lat, //  + ((-.5+Math.random())*10)
                                lng: this.state.location.lng //+ ((-.5+Math.random())*10)
                            }
                        }
                        console.log(story);
                        addStory(story);
                        
                        setTimeout(() => {
                            this.setState({
                                storyPath: `/${story.id}`
                            })
                        }, 1250);

                         // console.log("openpage!");
                        //window.open(`/${story.id}`,"_self")
                        // console.log("add", story);
                        // go to the page refer page


                    }, 500)
                }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting  }) => (
                        
                       
                        <form className={styles.formElement} onSubmit={handleSubmit}>

                            { this.state.storyPath == null &&
                            <div>
                            <Form.Group>
                                <Form.Label>Share your experience of self-quarantining.</Form.Label>
                            </Form.Group>

             
                             <Form.Group  >
                             {/* <pre>
                                {JSON.stringify(values)}
                                </pre> */}
                                <Form.Label>What do you see from your window? Please upload a picture of your view</Form.Label>
                                <ImageUpload setImagePath={this.setImagePath} />
                                <Form.Control
                                    ref={this.myRefImgPath}
                                    style={{visibility:'hidden', height:'0px', overflow:'hidden'}}
                                    type="text"
                                    name="imgUrl"
                                    id="imgUrl"
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.imgPath || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    values={values.imgUrl}
                                    vi
                                    className={touched.imgUrl && errors.imgUrl ? styles.hasError : null}
                                />
                                <Error touched={touched.imgUrl} message={errors.imgUrl} />
                            </Form.Group>

                            {/* locationName */}
                            <Form.Group  >
                                <Form.Label>City</Form.Label>
                                <LocationPick setLocationFromPicker={this.setLocationFromPicker} />        

                                <FormControl
                                    ref={this.myRefCityName}
                                    type="text"
                                    name="locationName"
                                    id="locationName"
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.location.name || "Not selected"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    values={values.locationName}
                                    className={touched.locationName && errors.locationName ? styles.hasError : null}
                                /> 
                                <Error touched={touched.locationName} message={errors.locationName} />
                            </Form.Group>

                            {/* FirstName */}
                            <Form.Group  >
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="Name" 
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    values={values.firstName}
                                    className={touched.firstName && errors.firstName ? styles.hasError : null}
                                />
                                <Error touched={touched.firstName} message={errors.firstName} />
                            </Form.Group>

                            {/* twitterHandle */}
                            <Form.Group  >
                                <Form.Label>Twitter (optional)</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        name="twitterHandle"
                                        id="twitterHandle"
                                        placeholder="handle" 
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        values={values.twitterHandle}
                                        className={touched.twitterHandle && errors.twitterHandle ? styles.hasError : null}
                                    />
                                </InputGroup>
                                <Error touched={touched.twitterHandle} message={errors.twitterHandle} />
                            </Form.Group>

                           {/* Story */}
                           <Form.Group  >
                                <Form.Label>Your Story
                                    { this.state.location.name != null &&
                                        <span> from {this.state.location.name}</span>
                                    }
                                </Form.Label>
                                <Form.Control
                                    as="textarea" 
                                    rows="10" 
                                    type="text"
                                    name="story"
                                    id="story"
                                    placeholder="During my quarantine..."
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    values={values.story}
                                    className={touched.story && errors.story ? styles.hasError : null}
                                />
                                <Error touched={touched.story} message={errors.story} />
                            </Form.Group>

                            
                            
                            <Form.Group className={styles.submitFrame} >
                                <label>By submitting this form you agree to our <Link to="/privacy"><u>privacy policy</u></Link></label>
                                <br/>
                                <button className={styles.tellStoryButton} type="submit" disabled={isSubmitting} >Submit</button>
                            </Form.Group>
                            
                            
                            </div>
                            }

                            { this.state.storyPath != null && 
                                <Form.Group >
                                    Thank you for your contribution to Quarantine Stories.
                                    <Link to={this.state.storyPath}>
                                        <div className={styles.tellStoryButton}>
                                            Click here to view your story
                                        </div>
                                    </Link>
                                </Form.Group>
                            }
                        </form>
                    )}
                </Formik>
            </Container>
        )
    }
};

export default ExploreList;
