import React, { Component } from "react";
import ReactDOM from "react-dom";
import styles from "./main.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

// import App from './js/App'
import NotFound from './js/NotFound'

import ExploreList from './js/components/Content/show/ExploreList'

import About from './js/components/Content/about/About'
import Privacy from './js/components/Content/gdpr/Privacy'
import MapContainer from "./js/components/Content/map/Map"
import MapContainerStatic from "./js/components/Content/map/MapStatic"
import Tell from "./js/components/Content/tell/Tell"
import ShowStory from "./js/components/Content/show/ShowStory"
import WindowDimensionsProvider from './js/components/WindowDimensionsProvider'
// import Footer from './js/components/Content/footer/index'

import Content from './js/components/Content'
//import history from './js/history'; history={history}


import { StoriesProvider } from './state/StoriesContext';

function StoryView(triggerAction) {
  let { slug } = useParams();

  //console.log('styles', styles);

  return (
    <Content 
        childLeft={ShowStory} 
        childRight={MapContainerStatic}
        showExploreButton={true}
        slug={slug}
        showHeader={true}
        // childRight={<MapContainerStatic slug={slug} />} 
      //showExploreButton={true} 
    />
  );
}

const routes = (
    <WindowDimensionsProvider>
      <StoriesProvider>
        <Router >
          <Switch>
            <Route path="/tell">
              <Content childLeft={Tell} childRight={MapContainerStatic} showExploreButton={true} showHeader={true} />
            </Route>
            <Route path="/about">
              <Content childLeft={About} childRight={null} showExploreButton={true}  showHeader={false} />
            </Route>
            <Route path="/privacy">
              <Content childLeft={Privacy} childRight={null} showExploreButton={true}  showHeader={false} />
            </Route>
            <Route path="/:slug" children={<StoryView  />} />
            <Route exact path="/">
              <Content childLeft={ExploreList} childRight={MapContainer} showExploreButton={false} showHeader={true}  />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </StoriesProvider>
    </WindowDimensionsProvider>
)

ReactDOM.render(
  routes,
  document.getElementById("container")
);