import React, { Component } from "react";
import {Map, InfoWindow, Marker, Circle, GoogleApiWrapper} from 'google-maps-react';
import styles from './Map.scss'
import StoriesContext from '../../../../state/StoriesContext';
import MarkerCluster from "./ClusterMarkers";
import mapStyle from "./MapStyle";
const { GMAP_IP } = process.env;

class MapContainer extends Component {
    static contextType = StoriesContext

    constructor() {
        super();
        
        this.couter = 0;
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            currentLatLng: {
              lat: 0,
              lng: 0
            },
            storedCenter: {
              lat: 0,
              lng: 0
            },
            centerLatLng: {
              lat: 45.0,
              lng: 10.0
            },
            radius: 0,
            isMarkerShown: false,
            markers: []
        };

        this.centerMoved = this.centerMoved.bind(this);
        this.updateRange = this.updateRange.bind(this);
        this.inBetweenTrigger = this.inBetweenTrigger.bind(this);
        this.storiesNearMe = this.storiesNearMe.bind(this);
        this.resetMap = this.resetMap.bind(this);
    }

    inBetweenTrigger(mapProps, map) {

      const { updateZoom } = this.context;
      //console.log(mapProps.zoom);
      updateZoom(map.getZoom());
      //console.log(mapProps);

      this.centerMoved(mapProps, map)
    }

    // showCurrentLocation = (that) => {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition( position => {
    //         that.setState({
    //           centerLatLng: {
    //             lat: position.coords.latitude,
    //             lng: position.coords.longitude
    //           },
    //           storedCenter: {
    //             lat: position.coords.latitude,
    //             lng: position.coords.longitude
    //           }
    //         })
    //       }
    //     )
    //   } else {
    //     alert("Please enable location sharing for your browser.");
    //     error => console.log(error)
    //   }
    // }

    componentDidMount() {
    }

    _mapLoaded(mapProps, map) {
        this.centerMoved(mapProps, map);
        map.setOptions({
            styles: mapStyle
        })
    }

    storiesNearMe() {
      const { nearMeMap } = this.context;
      nearMeMap();
    }

    resetMap() {
      const { resetMap } = this.context;
      resetMap();
    }

    updateRange(_map) {
      if(_map.getBounds()) {
          var bounds = _map.getBounds();
          var center = bounds.getCenter();
          var ne = bounds.getNorthEast();
          var r = 3963.0;
          var lat1 = center.lat() / 57.2958; 
          var lon1 = center.lng() / 57.2958;
          var lat2 = ne.lat() / 57.2958;
          var lon2 = ne.lng() / 57.2958;
          var dis = r * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));
          return dis
        } else {
          return 10000.0
        }
    }

    centerMoved(mapProps, map) {
      const { updateCenter } = this.context
      let radius = this.updateRange(map)
      updateCenter(
        {
          lat: map.center.lat(), lng: map.center.lng()
        },
        {
          radius: radius
        }
      )
    }

    render() {
        const { stories, initialCenter, mapCenter, zoom, markers, highlightStory } = this.context;

        var center = mapCenter; //this.state.centerLatLng;
        var interactive = true;

        return (
          <div>
            <div className={styles.storiesNearMe}>
              <button onClick={this.storiesNearMe}>Stories near me</button><br />
              
              { zoom >= 5 &&
              <button onClick={this.resetMap}>Reset map</button>
              }
            </div>
            <Map 
                center={center}
                initialCenter={initialCenter}
                google={this.props.google} 
                zoom={zoom}
                style={{width: '100%', height: '100%', position: 'relative'}}
                onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                className={styles.map}
                zoomControl={true}
                panControl={true}
                mapTypeControl ={false}
                fullscreenControl={false}
                streetViewControl={false}
                onDragend={this.inBetweenTrigger}
                onZoom_changed={this.inBetweenTrigger}
                scrollwheel={interactive}
                draggable={interactive} 
                keyboardShortcuts={interactive} 
                disableDoubleClickZoom={interactive} 
            >
           
            

            { highlightStory != null &&
                <Marker 
                    title={'#1'} 
                    name={'1'} 
                    zIndex={1}
                    position={
                    {
                      lat: highlightStory.location.lat,
                      lng: highlightStory.location.lng
                    }
                    } 
                    icon={{
                    url: "resources/image/icon-black-01.png",
                    anchor: new google.maps.Point(12,12),
                    scaledSize: new google.maps.Size(24,24)
                    }} 
                />
            }


{ markers.length > 0 &&
                <MarkerCluster 
                  markers={markers}
                  click={this.onMarkerClick}
                  mouseover={this.onMouseOver}
                  mouseout={this.onMouseOut}

                />
            }

            </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: (GMAP_IP)
  })(MapContainer)