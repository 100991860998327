import React, { Component, useState } from 'react';
import {Button, ButtonGroup} from "react-bootstrap";
import styles from "./Footer.scss";
import { Link } from "react-router-dom";
// import { useWindowDimensions } from '../../WindowDimensionsProvider'
import ResponsiveLayout from '../../../components/ResponsiveLayout'
import { IoLogoInstagram, IoLogoTwitter } from "react-icons/io";
class Footer extends Component {

        constructor() {
            super()
        }

        render () {
            return(
                <div className={styles.footer}>
                     <div className={styles.footerCenter} >
                        <a target="_blank" href="https://twitter.com/QuarantineMap">
                            <div><IoLogoTwitter /></div>
                        </a>
                        <a target="_blank" href="https://www.instagram.com/quarantinestories.today/">
                            <div><IoLogoInstagram /></div>
                        </a>
                    </div>
                    <div className={styles.footerLeft}>
                        <div><i>Quarantine Stories</i> 
                            {!this.props.mobile &&
                                <span> is a self-initiated project by <a target="_blank" href="https://rndr.studio">RNDR</a></span>
                            }
                        </div>
                    </div>
                   
                    <div className={styles.footerRight}>
                        <ButtonGroup className={styles.buttonGroup}>
                            { this.props.showExploreButton &&
                                <Link to="/">
                                    <Button variant="secondary">Stories</Button>
                                </Link>
                            }
                            <Link to="/about">
                                <Button variant="secondary">About</Button>
                            </Link>
                        </ButtonGroup>
                    </div>
                </div>
            )
        }
}

export default Footer

