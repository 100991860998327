import React, {Component} from 'react';
import {Button, Container, Row, Col } from "react-bootstrap";
import styles from "./About.scss"
import { Link } from "react-router-dom";

class About extends Component {

    constructor() {
        super()
    }

    render() {
        return (
            <Container className={styles.containerAbout} >
                        <Row >
                            <Col>
                                <br />  
                                <br />  
                                <Row >
                                    <h1><i>Quarantine Stories</i></h1>
                                </Row>
                                <Row>                                    
                                    <h1>What do you see from your window?</h1>
                                </Row>
                                <Row>
                                    <Link to="/tell">
                                        <Button className={styles.tellStoryButton} variant="primary" size="lg">
                                            Tell your story
                                        </Button>
                                    </Link>
                                    <Link to="/">
                                        <Button className={styles.tellStoryButton} variant="primary" size="lg">
                                            Read the stories
                                        </Button>
                                    </Link>
                                </Row>
                                <Row style={{maxWidth:600}}>
                                <p>Quarantine Stories is a collaborative platform that collects stories of isolation as a result of home-quarantining in efforts to reduce the effects of COVID-19.</p>
                                <p>The outbreak first started in Wuhan, China, and has continued spreading in a <a target="_blank" href="https://www.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6">growing number of other locations internationally</a>, including Italy, South Korea and Iran over the past months. Several citizens have been challenged to change their habits and adapt to this emergency by following strict measures, such as <a target="_blank" href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public">home-staying, travel restrictions and highly hygienic norms</a>. Data, testimonies and information are spread around the internet and constantly accessible on multiple media platforms. </p>
                                <p>Quarantine Stories gathers all personal stories of those in isolation, providing a single collaborative resource to support many, in an effort to document the effects of social distancing.</p>
                                <p>Even in isolation, we are together. Take a picture from your view through your window. Upload it and write your personal story. Write your story in the language you feel comfortable in, but keep in mind that the goal of the project is to reach the broadest audience possible. </p>
                                <p>Share the view from your window and the stories behind it. </p>
                                <p>Quarantine Stories is a self-initiated project created by <a target="_blank" href="https://rndr.studio">RNDR</a>. Our intent is to turn data into stories to build a collaborative experience in support of communities. This is an experimental project, the next phase of this project is uncharted. </p>
                                <p>Help hospitals and scientific research by donating to one these <a target="_blank" href="https://www.gofundme.com/mvc.php?route=homepage_norma/search&term=coronavirus">campaigns</a> of your choice.</p>
                                <p><Link to="/privacy">Read here about our Privacy Policy.</Link></p>
                                </Row>
                                <Row>
                                   <p>For questions feel free to contact us at <a href="mailto:info@rndr.studio">info@rndr.studio</a>.</p>
                                </Row>
                            </Col>
                        </Row>
            </Container>
        );
    }
};

export default About;
