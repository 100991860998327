import React, { Component } from 'react'
import { base } from "../js/base";
import _ from "underscore";
import Resizer from 'react-image-file-resizer';
import https from 'https';

const StoriesContext = React.createContext()

function arePointsNear(checkPoint, centerPoint, km) {
  // console.log(checkPoint, centerPoint, km)
  var ky = 40000 / 360;
  var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
  var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  // console.log(kx, dx, dy)
  return Math.sqrt(dx * dx + dy * dy) <= km;
}

class StoriesProvider extends Component {
    // Context state
    constructor() {
      super()
      this.currentLength = 0
      this.state = {
        mapCenter: null,
        mapRadius: null,
        initialCenter: {
          lat: 45.0,
          lng: 10.0
        },
        loading: true,
        initialZoom: 4,
        highlightStory: null,
        markers: [],
        stories: {},
        storiesSidebar: [],
        debug: false,
        zoom: 4,
        selectedStorySlug: null,
        hasBeenLikedInSession: []
      }
      this.mapState = {
        
      }
    }
    
    // Method to update state
    addStory = (story) => {
        const stories = {...this.state.stories};
        stories[story.id] = story
        this.setState({stories});

   
        

        //this.checkForSidebarStories(this);
        //this.configMarkers(this);

        // window.location.href = `/${story.id}`;
    }

    setHighlightStory = (story) => {
      this.setState({ 
        highlightStory: story
      });
    }

    resetMap = () => {
      this.setState({
        mapCenter: this.state.initialCenter,
        zoom: this.state.initialZoom
      })
    }

    nearMeMap = () => {
      this.setState({
        mapCenter: this.state.initialCenter,
        zoom: 8
      })
    }

    showOnMap = (targetCenter) => {
      this.setState({
        mapCenter: targetCenter,
        zoom: 8
      })
    }

    updateLike = (_story) => {
        if(!this.state.hasBeenLikedInSession.includes(_story.id)) {
          
          const stories = {...this.state.stories};
          _story.likes = _story.likes + 1;
          stories[_story.id] = _story;
          this.setState({stories});

          this.setState({
            hasBeenLikedInSession: [...this.state.hasBeenLikedInSession, _story.id]
          });

        }
    }

    updateCenter = (center, radius) => {
      this.setState({
        mapCenter: center,
        mapRadius: radius
      })
      this.checkForSidebarStories(this)
    }

    checkForSidebarStories(that) {
      var storiesObj = that.state.stories;
      const storiesArray = Object.keys(storiesObj).map(i => storiesObj[i])
      var sideBarSubset = []
      
      storiesArray.forEach(function(story) {
        if( arePointsNear(story.location, that.state.mapCenter, (that.state.mapRadius.radius)) ) {
            sideBarSubset.push(story);
        }
      });

      // SORT BY LIKE
      var sideBarSubsetLike = _.sortBy(sideBarSubset, function(num){ return -num.likes; });
      // SORT BY DATE
      var sideBarSubsetDate = _.sortBy(sideBarSubsetLike, function(num){ return -num.timeStamp; });
      // LIMIT
      var sideBarSubsetLimit = _.filter(sideBarSubsetDate, function(num, index){ 
        return index < 100; 
      });
      
      that.setState({
        storiesSidebar: sideBarSubsetLimit
      });
    }
    
    updateZoom = (_zoom) => {

      this.setState({
        zoom: _zoom
      })
    
    }
    

    setCenter = (_center) => {
      var targetZoom = 12;
      if(this.state.zoom >= 12) {
        targetZoom = this.state.zoom + 2;
      }
      this.setState({
        mapCenter: _center,
        zoom: targetZoom
      })
    }

    configMarkers(that) {
        var storiesObj = that.state.stories;
        const storiesArray = Object.keys(storiesObj).map(i => storiesObj[i])

        var markers = []
        storiesArray.forEach(function(marker, index) {
            if(marker.location != undefined) {
              var _lat = marker.location.lat
              var _lng = marker.location.lng
              markers.push(
                {
                  position: {
                    lat: _lat,
                    lng: _lng,
                  },
                  name: '',
                  description: '',
                  centerOnMap: () => {
                    //console.log("center this");
                    that.setCenter({lat: _lat, lng: _lng});
                  }
                }
              )
            }
        });

        this.setState({
          markers: markers
        });

        this.currentLength = markers.length
    }

    ipLookUp = () => {
      var that = this;
        https.get('https://ipapi.co/json/', function(resp){
          if(resp.statusCode == 200) {
            var body = ''
            resp.on('data', function(data){
                body += data;
            });
            resp.on('end', function(){
                var loc = JSON.parse(body);
                that.setState({
                  mapCenter: {
                    lat: loc.latitude,
                    lng: loc.longitude
                  },
                  initialCenter: {
                    lat: loc.latitude,
                    lng: loc.longitude
                  },
                  zoom: 4
                });
            });
          }
        });
    }
    
    componentWillMount() {
      var that = this;
      this.ipLookUp();
      
      this.storiesRef = base.syncState('stories', {
          context: this,
          state: 'stories',
          then() {
            that.checkForSidebarStories(that);
            that.configMarkers(that);
            that.setState({ 
              loading: false
            });
          },
      });
    }

    componentDidUpdate() {
      const storiesArray = Object.keys(this.state.stories).map(i => this.state.stories[i])
      //
      // console.log("did i update?", this.currentLength, storiesArray.length)

      // if(this.currentLength != storiesArray.length) {
      //   this.checkForSidebarStories(this);
      //   this.configMarkers(this);
      // }
      
    }
    

    componentWillUnmount() {
      // console.log("did unmount");
      base.removeBinding(this.storiesRef);
    }
  
    render() {
      const { children } = this.props
      const { stories, zoom, storiesSidebar, mapCenter, initialCenter,  mapRadius, highlightStory, markers, loading } = this.state
      const { addStory, updateCenter, setHighlightStory, updateLike, setCenter, updateZoom, resetMap, nearMeMap, showOnMap } = this

      return (
        <StoriesContext.Provider
          value={{
            stories,
            storiesSidebar,
            mapCenter,
            mapRadius,
            initialCenter,
            zoom,
            highlightStory,
            markers,
            loading,
            addStory,
            updateCenter, 
            setHighlightStory,
            updateLike,
            setCenter,
            updateZoom,
            resetMap,
            nearMeMap,
            showOnMap
          }}
        >
          {children}
        </StoriesContext.Provider>
      )
    }
  }
  
export default StoriesContext

export { StoriesProvider }
