import React, { Component } from "react";
import {Map, InfoWindow, Marker, Circle, GoogleApiWrapper} from 'google-maps-react';
import styles from './Map.scss'
import StoriesContext from '../../../../state/StoriesContext';
import _ from "underscore";
import mapStyle from "./MapStyle";
const { GMAP_IP } = process.env;

class MapContainerStatic extends Component {
    static contextType = StoriesContext

    constructor() {
        super();
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            currentLatLng: {
              lat: 0,
              lng: 0
            },
            storedCenter: {
              lat: 0,
              lng: 0
            },
            centerLatLng: {
              lat: 30.0,
              lng: 0.0
            },
            radius: 0,
            isMarkerShown: false,
            markers: [],
            
        };

        this.centerMoved = this.centerMoved.bind(this);
        this.updateRange = this.updateRange.bind(this);
    }

    showCurrentLocation = (that) => {
      
      //console.log("check location!");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition( position => {

            //console.log("got location", position.coords.latitude, position.coords.longitude );
            
            that.setState({
              centerLatLng: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              },
              storedCenter: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }
            })


          }
        )
      } else {
        error => console.log(error)
      }
    }

    componentDidMount() {
      //console.log("aha");
      //this.showCurrentLocation(this);
    }

    _mapLoaded(mapProps, map) {
        this.centerMoved(mapProps, map);
        map.setOptions({
            styles: mapStyle
        })
        
    }

    updateRange(_map) {
      if(_map.getBounds()) {
          var bounds = _map.getBounds();

          var center = bounds.getCenter();
          var ne = bounds.getNorthEast();

          var r = 3963.0;  

          var lat1 = center.lat() / 57.2958; 
          var lon1 = center.lng() / 57.2958;
          var lat2 = ne.lat() / 57.2958;
          var lon2 = ne.lng() / 57.2958;

          var dis = r * Math.acos(Math.sin(lat1) * Math.sin(lat2) + 
            Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));
          
          return dis
        } else {
          return 10000.0
        }
    }

    centerMoved(mapProps, map) {
      const { updateCenter } = this.context
      let radius = this.updateRange(map)
      
      updateCenter(
        {
          lat: map.center.lat(), lng: map.center.lng()
        },
        {
          radius: radius
        }
      )
    }

    render() {
        const { stories, mapCenter, initialCenter, markers, highlightStory } = this.context;
 
        var zoom = 2.2;
        var center = mapCenter;
        var interactive = false;
        var showMarker = false;


        if( mapCenter != null) {
          if(mapCenter.lat != 0 && mapCenter.lng != 0) {
             zoom = 10;
             showMarker = true;
          }
        }

        if(this.props.slug) {
            var storiesList = []
            const storiesArray = Object.keys(stories).map(i => stories[i]);
        
            if(storiesArray.length > 0) {
                var that = this;
                var story = _.filter(storiesArray, function(item, index) {
                    return item.id == that.props.slug;
                })[0];
                storiesList.push(story);
            }

            if(storiesList.length> 0) {
              // console.log(storiesList[0]);
              center = {
                lat: storiesList[0].location.lat,
                lng: storiesList[0].location.lng
              }
            }

            zoom = 10;
            showMarker = true;
        }

        return (
          <div>
            <Map 
                center={center}
                initialCenter={initialCenter}
                google={this.props.google} 
                zoom={zoom}
                style={{width: '100%', height: '100%', position: 'relative'}}
                onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                className={styles.map}
                zoomControl={true}
                panControl={true}
                mapTypeControl ={false}
                fullscreenControl={false}
                streetViewControl={false}
                onDragend={this.centerMoved}
                onZoom_changed={this.centerMoved}

                scrollwheel={interactive}
                draggable={interactive} 
                keyboardShortcuts={interactive} 
                disableDoubleClickZoom={interactive}
              
            >
             
            { showMarker &&
            <Marker 
                title={'#1'} 
                name={'1'} 
                zIndex={100}
                position={center} 
                icon={{
                url: "resources/image/icon-black-01.png",
                anchor: new google.maps.Point(12,12),
                scaledSize: new google.maps.Size(24,24)
                }} 
            /> 
            }
 
            </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: (GMAP_IP)
  })(MapContainerStatic)