import React from 'react';
import Geocode from "react-geocode";
const { GMAP_IP } = process.env;

class LocationPick extends React.Component {

  constructor() {
        super()
  
        this.onBlur = this.onBlur.bind(this);
  }

  onBlur(event) {
    event.persist()
    console.log('onblur:' + event);

    var that = this;

    Geocode.setApiKey(GMAP_IP);
    Geocode.setLanguage("en");
    Geocode.fromAddress(`${event.target.value}`).then(
      response => {
        var location = response.results[0].geometry.location
        that.props.setLocationFromPicker(
          {
            lat: location.lat,
            lng: location.lng,
            name: event.target.value
          }
        )
        
        event.target.placeholder = "Change city";
        event.target.value = "";

      },
      error => {
        console.error(error);
      }
    );
  }

  render() {
    return (
      <div>
        <input style={{padding:'5px', borderRadius:"5px", marginBottom:'10px'}} placeholder="Search" onBlur={this.onBlur} />
      </div>
    );
  }
}

export default LocationPick;