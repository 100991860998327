import React, {Component} from 'react';
import {Button, Container, Row, Jumbotron, Spinner, Navbar, ButtonToolbar, ListGroup, Image, Media, Col, Card, CardDeck, footer, ButtonGroup} from "react-bootstrap";
import styles from "../../../App.scss"
// import MapContainer from "./Map"
import CardView from "./card/Card"
import StoriesContext from '../../../../state/StoriesContext';
import LazyLoad from 'react-lazyload';
import ResponsiveLayout from '../../ResponsiveLayout'
import { Link } from "react-router-dom";

class ExploreList extends Component {
    static contextType = StoriesContext

    constructor() {
        super()
    }

    render() {
        const { stories, storiesSidebar, loading } = this.context
        var storiesList = []
        var storiesListMobile = []
       
        storiesSidebar.forEach(function(story, index) {
              storiesList.push(
                <LazyLoad key={index} overflow throttle={100} height={200}>
                    <CardView key={index} story={story} />
                </LazyLoad>
              )
              storiesListMobile.push(
                <LazyLoad key={index}  throttle={100} height={200}>
                    <CardView key={index} story={story} />
                </LazyLoad>
              )
        })

        function NoStories() {
            return (
                <div className={styles.noStories}>There are no stories in your selected area
                    <br />
                    <br />
                    <div className={styles.button}>
                        <Link to="/tell">
                            <Button className={styles.tellStoryButton} variant="primary" size="lg">
                                Tell your story
                            </Button>
                        </Link>
                    </div>
                </div>
            )
        }

        function LoadingStories() {
            return (
                <div className={styles.loadingStories}>...</div>
            )
        }
        
        return (
            <ResponsiveLayout breakPoint={840}
                renderDesktop={() => (
                    <Container >
                            <Row >
                                {storiesList.length == 0 && loading == true &&
                                    <LoadingStories />
                                }
                                {storiesList.length == 0 && loading == false &&
                                    <NoStories />
                                }
                                {storiesList.length != 0 &&
                                <ListGroup className={styles.ListGroup}>
                                    {storiesList}
                                </ListGroup>
                                }
                            </Row>
                    </Container> 
                 )}
                 renderMobile={() => (
                    <Container >
                    <Row >
                        {storiesList.length == 0 && loading == true &&
                            <LoadingStories />
                        }
                        {storiesList.length == 0 && loading == false &&
                            <NoStories />
                        }
                        {storiesList.length != 0 &&
                            <ListGroup className={styles.ListGroup}>
                                {storiesListMobile}
                            </ListGroup>
                        }
                    </Row>
                    </Container> 
                 )}
               />


        );
    }
};

export default ExploreList;