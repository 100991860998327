import React, {Component} from 'react';
// import { StoriesProvider } from '../../../../state/StoriesContext'
// import {Button, Container, Row, Jumbotron, Spinner, Navbar, ButtonToolbar, ListGroup, Image, Media, Col, Card, CardDeck, footer, ButtonGroup} from "react-bootstrap";
import styles from "./Content.scss"
import classNames from 'classnames'
// import { Link } from "react-router-dom";
import {  HeaderOne, HeaderTwo, HeaderThree, HeaderFour } from "./header"
import ResponsiveLayout from '../ResponsiveLayout'
// import { Footer } from 'react-bootstrap/lib/Modal';
// import { StoriesContext } from '../../../../state/StoriesContext'
import Headroom from "react-headroom"
// static contextType = StoriesContext
// const { stories } = this.context
// console.log(stories);
// const storiesArray = Object.keys(stories).map(i => stories[i]);
let lastScrollY = 0;

let ticking = false;

class DesktopView extends Component {

    constructor() {
        super()

        this.state = {
            largeMap: false
        }
        
        this.mapLarge = this.mapLarge.bind(this);
        this.mapSmall = this.mapSmall.bind(this);
        this.nav = React.createRef();
    }

    mapLarge() {
        this.setState({
            largeMap: true
        })
    }

    mapSmall() {
        this.setState({
            largeMap: false
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        lastScrollY = window.scrollY;
        var that = this;
        if (!ticking) {
          window.requestAnimationFrame(() => {
            if(this.nav.current.style.top != lastScrollY) {
                that.mapSmall();
            }
            this.nav.current.style.top = `${lastScrollY}px`;
            ticking = false;
          });
       
          ticking = true;
        }
    };
        

    render() {

        var mapClasses = `${styles.bubble} `
        if(this.state.largeMap) {
            mapClasses += `${styles.largeMap} `
        }

        const { LeftChild, RightChild, Footer, slug } = this.props

        return(

            <ResponsiveLayout breakPoint={840}
      renderDesktop={() => (
                        <div className={classNames(styles.parent, this.props.layout)} >
                            { this.props.showHeader && 
                                <div className={styles.header}>
                                    
                                    
                                    <div className={styles.headerItem}>
                                        <HeaderOne />
                                    </div>
                                    <div className={styles.headerItem}>
                                        <HeaderTwo />
                                    </div>
                                    { this.props.layout != styles.mobile &&
                                        <div className={styles.headerItem}>
                                            <HeaderThree />
                                        </div>
                                    }
                                    { this.props.layout != styles.mobile &&
                                        <div className={styles.headerItem}>
                                            <HeaderFour />
                                        </div>
                                    }
                                </div>
                            }
                            <div className={styles.center}>
                                <div className={styles.left}>
                                        <LeftChild slug={slug} />
                                </div>

                                { RightChild != null &&
                                <div className={styles.right}>
                                    <RightChild slug={slug} />
                                </div>
                                }

                            </div>
                            <div className={styles.footer}>
                                {Footer}
                            </div>
                    </div>
      )}
      renderMobile={() => (
                    <div className={classNames(styles.parent, this.props.layout)} >
                   
                    <div  ref={this.nav} onClick={this.mapSmall}>
                        { this.props.showHeader && 
                            <Headroom style={{
                                webkitTransition: 'all .5s ease-in-out',
                                mozTransition: 'all .5s ease-in-out',
                                oTransition: 'all .5s ease-in-out',
                                transition: 'all .5s ease-in-out'
                            }}>
                                <div className={styles.header}>
                                    <div className={styles.headerItem}>
                                        <HeaderOne />
                                    </div>
                                    <div className={styles.headerItem}>
                                        <HeaderTwo />
                                    </div>
                                </div>
                            </Headroom>
                        }
                        <LeftChild slug={slug} />
                        <div className={styles.footer}>{Footer}</div>
                    </div>
                    { RightChild != null &&
                        <div className={mapClasses} onMouseLeave={this.mapSmall} onClick={this.mapLarge}>
                            <div className={styles.right}>
                                <RightChild slug={slug} />
                            </div>
                        </div>
                    }
            </div>
      )}
    />
            
        )
    }

}



export default DesktopView;