import Rebase from 're-base';
import firebase from 'firebase';

const { apiKey } = process.env;
const { authDomain } = process.env;
const { databaseURL } = process.env;
const { projectId } = process.env;
const { storageBucket } = process.env;
const { messagingSenderId } = process.env;
const { appId } = process.env;
const { measurementId } = process.env;

const config = {
    apiKey: apiKey,
    authDomain: authDomain,
    databaseURL: databaseURL,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    measurementId: measurementId
};


const app = firebase.initializeApp(config);
const base = Rebase.createClass(app.database());
const storage = firebase.storage();

export { storage, base };
