import React, {Component} from 'react';
import {Button, Container, Row, Jumbotron, Spinner, Navbar, ButtonToolbar, ListGroup, Image, Media, Col, Card, CardDeck, footer, ButtonGroup} from "react-bootstrap";
import styles from "../../../App.scss"
import CardView from "./card/Card"
import StoriesContext from '../../../../state/StoriesContext';
import _ from 'underscore';

class ExploreList extends Component {
    static contextType = StoriesContext

    constructor() {
        super()
    }

    render() {
        const { stories } = this.context
        var storiesList = []
        const storiesArray = Object.keys(stories).map(i => stories[i]);
        
        if(storiesArray.length > 0) {
            var that = this;
            var story = _.filter(storiesArray, function(item, index) {
                return item.id == that.props.slug;
            })[0];
            storiesList.push( <CardView key={0} story={story} /> );
        }
       
        return (
            <Container >
                    <Row >
                        <ListGroup className={styles.ListGroup}>
                            {storiesList}
                        </ListGroup>
                    </Row>
             </Container> 
        );
    }
};

export default ExploreList;