import React, { Component, useState } from 'react';
import {Button, Container, Row, Jumbotron, ListGroup, FormControl, InputGroup, Badge, Modal, Image, Media, Col, Card, CardDeck, footer, ButtonGroup} from "react-bootstrap";
import styles from "./Card.scss"
import { GoHeart, GoLink } from "react-icons/go";
import StoriesContext from '../../../../../state/StoriesContext';
import moment from "moment"
import classnames from "classnames"
import ResponsiveLayout from '../../../ResponsiveLayout'
import ReadMore from '../../utils/ReadMore';


class CardView extends Component {
    static contextType = StoriesContext
    

    constructor() {
        super()
        const cardRef = React.createRef();
        
        this.state = {
            show: false,
            imageToggle: false,
        }

        this.focusStory = this.focusStory.bind(this);
        this.unFocusStory = this.unFocusStory.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleLike = this.handleLike.bind(this);
        this.changeHandle = this.changeHandle.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.showOnMap = this.showOnMap.bind(this);
    }

    handleLike() {
        const { updateLike } = this.context
        updateLike(this.props.story)
    }

    showOnMap() {
        const { showOnMap } = this.context
        showOnMap({lat:this.props.story.location.lat, lng:this.props.story.location.lng})
    }

    focusStory() {
        const { setHighlightStory } = this.context
        setHighlightStory(this.props.story)
    }

    unFocusStory() {
        const { setHighlightStory } = this.context
        setHighlightStory(null)
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    handleShow() {
        this.setState({
            show: true
        })
    }

    changeHandle() {

    }

    handleClick() {
        this.setState({
            imageToggle: !this.state.imageToggle
        })
    }

    render() {

            var imageClass = `${styles.bgImageContainter} `;
            if (this.state.imageToggle) imageClass += `${styles.toggled} `;

            const createFullPostMarkup = () => {
                return { __html: `${ this.props.story.story }` }
            }

            return (
                    <ListGroup.Item className={styles.ListGroupItem}>

                        <ResponsiveLayout breakPoint={1270}
                            renderDesktop={() => (
                                
                                <Card ref={this.cardRef} onMouseOver={this.focusStory} onMouseOut={this.unFocusStory} className={classnames(styles.Card, styles.desktop)} >
                                {/* <Card.Header >5 minutes ago</Card.Header> */}
                                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                <Card.Body className={styles.CardB}>
                                    <Media>
                                         
                                        <Image
                                            className="mr-3"
                                            width={"50%"}
                                            src={this.props.story.imgUrl}
                                            alt={`quarantine stories covid covid19 ${this.props.story.location.name}`}
                                        />
                                       
                                        <Modal show={this.state.show} onHide={this.handleClose}>
                                            <Modal.Header closeButton>
                                            <Modal.Title>Sharable link</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                            <InputGroup size="lg">
                                                <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroup-sizing-lg"><GoLink /></InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl  onChange={this.changeHandle} aria-label="Large" value={`http://quarantinestories.today/${this.props.story.id}`} aria-describedby="inputGroup-sizing-sm" />
                                            </InputGroup>
                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleClose}>
                                                Done
                                            </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Media.Body >
                                            <div className={styles.storyLocation}>
                                                <div className={styles.locationName} onClick={this.showOnMap}>{this.props.story.location.name}</div>
                                                <div>{moment(this.props.story.timeStamp).fromNow()}</div>
                                            </div>
                                            {/* <Link to={`/${this.props.story.id}`}> {this.props.story.id}</Link> */}
                                            <Card.Title className={styles.title}>{this.props.story.firstName}</Card.Title>
                                            { this.props.story.twitterHandle != '' && 
                                                <Card.Subtitle className={styles.handle}>
                                                    <a target="_blank" href={`https://twitter.com/${this.props.story.twitterHandle}`}>
                                                        @{this.props.story.twitterHandle}
                                                    </a>


                                                </Card.Subtitle>
                                            }
                                            <Card.Text className={styles.cardStory}>

                                            <ReadMore
                                            className={styles.readmore}
                                            charLimit={400}
                                            readMoreText=" read more"
                                            readLessText=""
                                            >
                                            {this.props.story.story}
                                            </ReadMore>

                                            </Card.Text>
                                            <div className={styles.actions}>
                                            <ButtonGroup className="mr-1" >
                                                <Button className={styles.supportbutton} onClick={this.handleLike}>
                                                <Badge className="mr-2" >{this.props.story.likes}</Badge>
                                                    <GoHeart />
                                                </Button>
                                            </ButtonGroup>
                                            <ButtonGroup className="mr-2">
                                                <Button onClick={this.handleShow} className={styles.sharebutton}>
                                                    Share
                                                </Button>
                                            </ButtonGroup>
                                            </div>
                                        </Media.Body>
                                    </Media>
                                </Card.Body>
                                </Card>
                                )}
                            renderMobile={() => (
                                <Card ref={this.cardRef} onMouseOver={this.focusStory} onMouseOut={this.unFocusStory} className={styles.Card} >
                                    <Row>
                                            <div className={imageClass} onClick={this.handleClick} >
                                                <div className={styles.bgImage} style={{backgroundImage:`url('${this.props.story.imgUrl}')`}}  ></div>
                                            </div>
                                    </Row>
                                    <Card.Body className={styles.CardB}>
                                        <Row>
                                            <Modal show={this.state.show} onHide={this.handleClose}>
                                                    <Modal.Header closeButton>
                                                    <Modal.Title>Sharable link</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                    <InputGroup size="lg">
                                                        <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroup-sizing-lg"><GoLink /></InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl  onChange={this.changeHandle} aria-label="Large" value={`http://quarantinestories.today/${this.props.story.id}`} aria-describedby="inputGroup-sizing-sm" />
                                                    </InputGroup>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                    <Button variant="secondary" onClick={this.handleClose}>
                                                        Done
                                                    </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                <Media.Body >
                                                    <div className={styles.storyLocation}>
                                                        <div className={styles.locationName} onClick={this.showOnMap}>{this.props.story.location.name}</div>
                                                        <div>{moment(this.props.story.timeStamp).fromNow()}</div>
                                                    </div>
                                                    {/* <Link to={`/${this.props.story.id}`}> {this.props.story.id}</Link> */}
                                                    <Card.Title className={styles.title}>{this.props.story.firstName}</Card.Title>
                                                    { this.props.story.twitterHandle != '' && 
                                                        <Card.Subtitle className={styles.handle}>
                                                            <a target="_blank" href={`https://twitter.com/${this.props.story.twitterHandle}`}>
                                                                @{this.props.story.twitterHandle}
                                                            </a>
                                                        </Card.Subtitle>
                                                    }
                                                     <Card.Text className={styles.cardStory}>
                                                                <ReadMore
                                                        className={styles.readmore}
                                                        charLimit={200}
                                                        readMoreText=" read more"
                                                        readLessText=""
                                                        >
                                                        {this.props.story.story}
                                                        </ReadMore>
                                                    </Card.Text>
                                                    <div className={styles.actions}>
                                                    <ButtonGroup className="mr-1" >
                                                        <Button className={styles.supportbutton} onClick={this.handleLike}>
                                                        <Badge >{this.props.story.likes}</Badge>
                                                            <GoHeart />
                                                        </Button>
                                                    </ButtonGroup>
                                                    <ButtonGroup className="mr-2">
                                                        <Button onClick={this.handleShow} className={styles.sharebutton}>
                                                            Share
                                                        </Button>
                                                    </ButtonGroup>
                                                    </div>
                                                </Media.Body>
                                        </Row>
                                </Card.Body>
                                </Card>
                            )}
                            />



                        


                    </ListGroup.Item>
            );
    };
}

export default CardView;