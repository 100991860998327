import React, { Component } from 'react';
import { storage } from "../../../base";
import Resizer from 'react-image-file-resizer';

function getOrientation(file, callback) {
    var reader = new FileReader();
    reader.onload = function(e) {

        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) != 0xFFD8)
        {
            return callback(-2);
        }
        var length = view.byteLength, offset = 2;
        while (offset < length) 
        {
            if (view.getUint16(offset+2, false) <= 8) return callback(-1);
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker == 0xFFE1) 
            {
                if (view.getUint32(offset += 2, false) != 0x45786966) 
                {
                    return callback(-1);
                }

                var little = view.getUint16(offset += 6, false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++)
                {
                    if (view.getUint16(offset + (i * 12), little) == 0x0112)
                    {
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
                    }
                }
            }
            else if ((marker & 0xFF00) != 0xFF00)
            {
                break;
            }
            else
            { 
                offset += view.getUint16(offset, false);
            }
        }
        return callback(-1);
    };
    reader.readAsArrayBuffer(file);
}



class ImageUpload extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            image: null,
            imageLow: null,
            url: '',
            urlLow: '',
            progress: 0
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    handleChange(event) {
        if(event.target.files[0]) {
            var that = this;
            var files = event.target.files[0];

          
            getOrientation(event.target.files[0], function(orientation) {
                var rotation = 0;
                // console.log(orientation);
                if(orientation == 6) {
                    rotation = 90
                }

                // high res
                Resizer.imageFileResizer(
                    files,
                    1920,
                    1920, 
                    'JPEG',
                    100,
                    rotation,
                    uri => {
    
                        var i = new Image();
                        i.onload = function(){
                            if(i.width>i.height) {
                                alert( "Please provide a vertical image" );
                            } else {
                                // console.log(i);
                                const image = uri.replace("data:image/jpeg;base64,", "");
                                that.setState({image})
                            }
                        };
                        i.src = uri;

                        // low res
                        Resizer.imageFileResizer(
                            files,
                            800,
                            800, 
                            'JPEG',
                            50,
                            rotation,
                            uri => {
                                var iLow = new Image();
                                iLow.onload = function(){
                                    if(iLow.width>iLow.height) {
                                    } else {
                                        // console.log(iLow);
                                        const imageLow = uri.replace("data:image/jpeg;base64,", "");
                                        that.setState({imageLow})
                                    }
                                };
                                iLow.src = uri;
                            },  
                            'base64'
                        );
                    },  
                    'base64'
                );
            });
        }
    }

    handleUpload(e) {
        e.preventDefault();
        const { image, imageLow } = this.state;

        var theDate = Date.now();

        // HIGH
        var filename = `quarantine_stories_${theDate}.jpg`
        
        const uploadTask = storage.ref(`images/${filename}`).putString(image, 'base64');
        uploadTask.on('state_changed', 
            (snapshot) => { // progress
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                this.setState({progress});
            }, 
            (error) => { // error
                console.log(error);
            }, 
            () => { // complete
                storage.ref('images').child(`${filename}`).getDownloadURL().then(url => {
                    //this.props.setImagePath(url)
                    this.setState({url});

                    // LOW
                    var filenameLow = `quarantine_stories_${theDate}_low.jpg`
                    const uploadTaskLow = storage.ref(`images/${filenameLow}`).putString(imageLow, 'base64');
                    uploadTaskLow.on('state_changed', 
                        (snapshot) => { // progress
                            //const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                            //this.setState({progress});
                        }, 
                        (error) => { // error
                            console.log(error);
                        }, 
                        () => { // complete
                            storage.ref('images').child(`${filenameLow}`).getDownloadURL().then(urlLow => {
                                this.props.setImagePath(urlLow)
                                this.setState({urlLow})
                            })
                    });
                })
        });

    }

    render() {
        return(
            <div>
                <progress value={this.state.progress} max="100" style={{width:'100%'}} />
                <br />
                <input  type="file" style={{maxWidth:"100%"}} onChange={this.handleChange} />
                <br />
                <br />
                <button onClick={this.handleUpload}>Upload</button>
                <br />
                <br />
                { this.state.urlLow != '' &&
                    <img src={this.state.urlLow}  width={"50%"} /> 
                }
            </div>
        )
    }
}

export default ImageUpload;