import React, {Component} from 'react';

import {Button, Container, Row, Jumbotron, Spinner, Navbar, ButtonToolbar, ListGroup, Image, Media, Col, Card, CardDeck, footer, ButtonGroup} from "react-bootstrap";
import styles from "./Header.scss"
import { Link } from "react-router-dom";
import StoriesContext from '../../../../state/StoriesContext';
import {useCountUp} from 'react-countup';

function pad(num, size) {
    var s = "000000" + num;
    return s.substr(s.length-size);
}

class HeaderOne extends Component {
    static contextType = StoriesContext;

    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { resetMap } = this.context;
        resetMap();
    }
    render() {
    return(
        <div className={styles.title}>
            <Link to="/">
                <div onClick={this.handleClick} className={styles.question}>What do you see from your window?</div>       
            </Link>
        </div >
        )
    }
}

class HeaderTwo extends Component {
    render() {
    return(
            <div className={styles.button}>
                <Link to="/tell">
                    <Button className={styles.tellStoryButton} variant="primary" size="lg">
                        Tell your story
                    </Button>
                </Link>
            </div>
        )
    }
}

class HeaderThree extends Component {
    render() {
    return(
        <div className={styles.about}>
            <i>Quarantine Stories</i> is a collaborative platform that collects stories of isolation as a result of home-quarantining in efforts to reduce the effects of COVID-19
        </div>
        )
    }
}

class HeaderFour extends Component {
    static contextType = StoriesContext

    constructor(props) {
        super(props)

        this.state = {
            hasAnimated: false
        }
    }
    
    componentDidMount() {
        
    }

    render() {

        const { stories } = this.context
        const storiesArray = Object.keys(stories).map(i => stories[i]);
        var that = this;

        const SimpleHook = () => {
            const { countUp } = useCountUp({ end: storiesArray.length, duration: 3.0, onEnd: ({}) => {
                that.setState({
                    hasAnimated: true
                })
            } });
            return <div>{pad(countUp, 5)} Stories</div>; 
        };

        return(
            <div className={styles.count}>
                { !this.state.hasAnimated &&
                    <SimpleHook /> 
                } 
                { this.state.hasAnimated &&
                    <div>{pad(storiesArray.length, 5)} Stories</div>
                }
            </div>
        )
    }
}

export {
    HeaderOne,
    HeaderTwo,
    HeaderThree,
    HeaderFour
}
