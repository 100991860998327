import React, { Component } from 'react'
import ResponsiveLayout from '../ResponsiveLayout'
import RenderView from './RenderView.js'
import styles from "./Content.scss"
import Footer from './footer/index'
import StoriesContext from '../../../state/StoriesContext'
// const { stories } = this.context
// console.log(stories);
// const storiesArray = Object.keys(stories).map(i => stories[i]);


class Content extends Component {
  static contextType = StoriesContext

  constructor() {
    super()
  }

  componentDidUpdate() {
    
  }

  render() {

    const { stories } = this.context
    // console.log(stories);

    return (
    <ResponsiveLayout breakPoint={840}
      renderDesktop={() => (
        <RenderView 
          LeftChild={this.props.childLeft} 
          RightChild={this.props.childRight}
          Footer={<Footer mobile={false} showExploreButton={this.props.showExploreButton} />} 
          layout={styles.desktop}
          slug={this.props.slug}
          showHeader={this.props.showHeader}
         />
      )}
      renderMobile={() => (
        <RenderView 
          LeftChild={this.props.childLeft} 
          RightChild={this.props.childRight} 
          Footer={<Footer mobile={true} showExploreButton={this.props.showExploreButton}  />}
          layout={styles.mobile}
          slug={this.props.slug} 
          showHeader={this.props.showHeader}
        />
      )}
    />
  )
      }

}

export default Content
