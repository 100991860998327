import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

// import MarkerClusterer from './markerclusterer'
// import * as MarkerClusterer from 'markerclustererplus'
import MarkerClusterer from './markerClusterer';

const evtNames = [
  'click',
  'dblclick',
  'dragend',
  'mousedown',
  'mouseout',
  'mouseover',
  'mouseup',
  'recenter',
]

const markerCluster = (props) => {
  const {map, google, markers} = props
  
  const handleEvent = ({event, marker, entry}) => {
    if (props[event]) {
      props[event]({
        props: props,
        marker: marker,
        event: event,
        entry: entry
      })
    }
  }
  


  // This hook works like ComponentWillMount
  // The  hook isn't really needed, this whole thing worked without it,
  // I added the hook so that I could implement a cleanup function
  useEffect(() => {
    //   console.log(markers);
    if (map && markers) {
      const mapMarkers = markers.map((marker) => {
        const entry = new google.maps.Marker({
          position: {
            lat: marker.position.lat,
            lng: marker.position.lng
          },
          map: map,
          name: marker.name,
          zIndex: 0,
          icon: {
            url: "resources/image/icons-01.png",
            anchor: new google.maps.Point(12,12),
            scaledSize: new google.maps.Size(24,24)
          },
        });

        entry.addListener('click', function() {
          //console.log("just got a click", marker)
          marker.centerOnMap();
          
        });

        entry.addListener('mouseover', function() {
           entry.setIcon({
            url: "resources/image/icon-black-01.png",
            anchor: new google.maps.Point(12,12),
            scaledSize: new google.maps.Size(24,24)
           })
        });

        entry.addListener('mouseout', function() {
          entry.setIcon({
           url: "resources/image/icons-01.png",
           anchor: new google.maps.Point(12,12),
           scaledSize: new google.maps.Size(24,24)
          })
       });
     
      

        // console.log(entry);

        
        evtNames.forEach(e => {
          entry.addListener(e, () => handleEvent({
            event: e,
            marker: marker,
            entry: entry
          }))
        })
        // console.log(mapMarkers);
        // console.log(entry);
        
        return entry
      })
      
      const clusterer = new MarkerClusterer(map, mapMarkers, 
        { 
            zIndex: 0,
            gridSize:30,
            imagePath: "resources/image/cluster-0",
        })

        
      // Cleanup function. Note, this is only returned if we create the markers
      return () => {
        
        clusterer.clearMarkers()
        
      }
    }
  }, [map, google, markers])
  
  
  // Do we need to render anything??
  return (null)
  
}

markerCluster.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  markers: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
  })),
}

export default markerCluster